import React, { useRef, useEffect, useState, useMemo } from "react";
import "./scroll.css";
import {
  StyleSheet,
  View,
  Text,
  Pressable,
  Modal,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { FontAwesome } from "@expo/vector-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  USER_FIRST_NAME,
  USER_GAME_NAME,
  USER_LAST_NAME,
} from "../../../config.inc";
import { editDate, createDate } from "../../../EditDate";
import { t } from "i18n-js";

import { ShortenFile } from "../../modules/ShortenFile.js";
import { removeType, setTypeMinimize, showFile } from "../../../redux/actions";
import UseResponsiveSize from "../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import ShortenWord from "../../modules/ShortenWord";
import PlayerCard from "./PlayerCard";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";

export default function GameInfo({ flag, setFlag, setDrag }) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();
  const styles = stylesR(width, height, heightS, widthS);

  const dispatch = useDispatch();

  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);
  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const minimizePopups = useSelector((state) => state.minimize.minimize);

  const printDescription = (desciption) => {
    let descriptionNew = desciption;
    if (desciption && desciption.length > 150) {
      descriptionNew = `${desciption.slice(0, 150)}...`;
    }
    return descriptionNew;
  };

  const checkType = (type) => {
    switch (type) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return images.filePhotoIcon;
      case "video/mp4":
        return images.fileVideoIcon;
      case "audio/mp3":
      case "audio/mpeg":
        return images.fileSoundIcon;
      default:
        return images.fileDocIcon;
    }
  };

  return (
    <View style={[styles.main, { backgroundColor: background.popup1 }]}>
      <View style={{ width: "100%", height: "100%" }}>
        <TouchableOpacity style={{ cursor: "grabbing" }} onPressIn={() => setDrag(true)}>
          <View style={[styles.header, { borderBottomColor: color.border }]}>
            <View style={styles.headerLeft}>
              <View
                style={[
                  styles.logo_view,
                  { backgroundColor: background.gameInfoLogo },
                ]}
              >
                <Image
                  source={{ uri: images.simulationInfo }}
                  style={styles.logo}
                />
              </View>
              <Text selectable={false} style={styles.textLogo}>{t("menu_info")}</Text>
            </View>
            <HeaderPopupButtons
              close={() => {
                setFlag();
                dispatch(removeType("simulation"));
              }}
              minimize={() =>
                dispatch(
                  setTypeMinimize({
                    name: "simulation",
                    type: 1,
                    show: false,
                  })
                )}
            />
          </View>
        </TouchableOpacity>
        <View style={styles.body}>
          <ScrollView
            nativeID="game"
            style={styles.scroll}
            contentContainerStyle={styles.scroll_main}
          >
            <View
              style={[styles.body_top, { borderBottomColor: color.border }]}
            >
              <Text selectable={false} style={styles.game_name}>
                {gameInfo && gameInfo.game_name}
              </Text>
              <Text
                selectable={false}
                style={[styles.game_start, { color: color.gameInfoGameStart }]}
              >
                {t("start-time")}:
                {gameInfo && editDate(createDate(gameInfo.start_time), "full")}
              </Text>
            </View>
            <View style={[styles.info, { borderBottomColor: color.border }]}>
              <Text selectable={false} style={styles.settingText}>
                {t("background-story")}
              </Text>
              <Text
                selectable={false}
                style={[styles.story, { color: color.gameInfoStory }]}
              >
                {gameInfo && gameInfo.background_story}
              </Text>
              {gameInfo.files && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {gameInfo.files.length > 0 && <Text
                    selectable={false}
                    style={[
                      styles.realAndRep,
                      {
                        alignSelf: "flex-start",
                        marginTop: heightS * -4,
                        marginRight: widthS * 12,
                      },
                    ]}
                  >
                    {t("files")}:
                  </Text>}
                  <View
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      maxWidth: "100%",
                    }}
                  >
                    {gameInfo.files.map((file, index) => {
                      const fileDetails = ShortenFile(file.name);

                      return (
                        <Pressable
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginRight: widthS * 10,
                            marginBottom: heightS * 10,
                          }}
                          onPress={() =>
                            dispatch(
                              showFile({
                                name: file.name,
                                type: file.type,
                                link: file.link,
                              })
                            )
                          }
                        >
                          <Image
                            source={{ uri: checkType(file.type) }}
                            style={{
                              width: widthS * 14,
                              height: widthS * 14,
                              resizeMode: "contain",
                            }}
                          />

                          <Text
                            numberOfLines={1}
                            selectable={false}
                            style={[
                              styles.name,
                              { textAlign: "center", width: widthS * 50 },
                            ]}
                          >
                            {fileDetails.fileName}
                          </Text>
                          <Text
                            selectable={false}
                            style={[
                              styles.name,
                              {
                                textAlign: "center",
                                width: widthS * 50,
                                lineHeight: widthS * 9,
                              },
                            ]}
                          >
                            {fileDetails.fileType}
                          </Text>
                        </Pressable>
                      );
                    })}
                  </View>
                </View>
              )}
            </View>
            <View style={styles.players_view}>
              <Text selectable={false} style={styles.list_Text}>
                {t("list-of-players")}
              </Text>
              <View style={styles.players} nativeID="players">
                {gameInfo.allPlayers &&
                  gameInfo.allPlayers.map((e, i) => (
                    <PlayerCard index={i} player={e} key={i} />
                  ))}
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    </View>
  );
}
const stylesR = (width, height, heightS, widthS) =>
  StyleSheet.create({
    main: {
      height: "100%",
      borderRadius: widthS * 15,
      display: "flex",
      flexDirection: "column",
      zIndex: 2,
      fontFamily: "'Catamaran', sans-serif",
    },
    header: {
      paddingLeft: widthS * 35,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerLeft: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingVertical: heightS * 4,
    },
    logo_view: {
      width: widthS * 26,
      height: widthS * 26,
      borderRadius: widthS * 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    logo: {
      width: widthS * 13,
      height: heightS * 13,
      resizeMode: "contain",
    },
    textLogo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 15,
      paddingLeft: widthS * 10,
    },
    x: {
      fontFamily: "Catamaran_300Light",
      fontSize: widthS * 14,
    },
    body: {
      marginLeft: widthS * 38,
      position: "relative",
      zIndex: 2,
      flex: 1,
    },
    scroll: {
      flex: 1,
      paddingRight: widthS * 25,
      zIndex: 3,
    },
    scroll_main: {
      flex: 1,
    },
    body_top: {
      borderBottomWidth: 1,
      borderStyle: "solid",
    },
    game_name: {
      color: "white",
      fontSize: widthS * 22,
      fontFamily: "Catamaran_300Light",
      marginTop: heightS * 6,
    },
    game_start: {
      fontSize: widthS * 11,
      fontFamily: "Catamaran_400Regular",
      marginBottom: heightS * 6,
    },

    info: {
      marginTop: heightS * 15,
      borderBottomWidth: 1,
      borderStyle: "solid",
      paddingBottom: heightS * 18,
    },
    settingText: {
      color: "white",
      fontSize: widthS * 12,
      fontFamily: "Catamaran_400Regular",
    },
    story: {
      paddingTop: heightS * 3,
      paddingBottom: heightS * 25,
      fontSize: widthS * 9.5,
      fontFamily: "Catamaran_600SemiBold",
    },
    list_Text: {
      color: "white",
      fontSize: widthS * 12,
      fontFamily: "Catamaran_400Regular",
      marginTop: heightS * 16,
      alignSelf: "flex-start",
    },
    realAndRep: {
      color: "white",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
      marginTop: 10,
      alignSelf: "flex-start",
    },
    players_view: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    players: {
      marginVertical: heightS * 8,
      paddingBottom: heightS * 35,
      width: "100%",
      display: "flex",
      alignSelf: "flex-start",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    name: {
      color: "white",
      fontSize: widthS * 9,
      textAlign: "left",
      fontFamily: "Catamaran_300Light",
    },
  });
