import { setTimeUnits } from "../../componnets/modules/createTimeColumns";
import {
  SET_EVENTS_CREATOR,
  SET_KPI,
  CHANGE_TIME_UNITS,
  CHANGE_FLOW_ZOOM,
  MARK_PLAYER,
  MARK_PLAYER_HOVER,
  SET_TOOLTIP_ACTION,
  CHANGE_COLUMN_POSITION,
  SET_NEW_SCROLL,
  EDIT_ACTION,
  ADD_ACTION_CREATOR,
  DELETE_ACTION_CREATOR,
  SET_FLOW_TIME_COLUMNS,
  SET_FLOW_FILTERS,
  SET_ACTION_CREATOR,
  SET_UNFINSHED_FLOW_COLUMNS,
  MARK_PLAYER_FILTER,
  CAN_START
} from "../actions/creatorActions";
const colorsMain = [
  "#520065",
  "#DE82D8",
  "#FCF000",
  "#007493",
  "#00B192",
  "#D98000",
  "#4D320F",
  "#724673",
  "#006ABC",
];
export let colors = [...colorsMain];

import { checkIfIsEncodeText } from "../../componnets/modules/removeSpacialCherectersAndSpaes";
import { createFileObject } from "../../componnets/modules/CreateFileObject";
import { createQuestionArr } from "../../componnets/modules/createQuestionArr";

const checkFile = (link) => {
  if (link?.length > 0) {
    if ((link?.charAt(link?.length - 4) === "." || link?.charAt(link?.length - 5) === ".")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const createFilesArr = (object) => {
  let arr = [];
  Object.keys(object).forEach((key) => {
    arr.push({ ...object[key], id: key });
  });
  return arr;
}

export const Swimlane = (
  state = {
    kpis: [],
    actions: [],
    timeUnints: "10m",
    filters: { player: "", event: "", platform: "" },
    inject: {
      1: [],
      2: [],
      3: [],
    },
    zoom: "100",
    playerCurrent: "",
    playerHover: "",
    tooltip: null,
    editAction: null,
    scroll: 0,
    flowTimeColumns: [...new Array(20)].map((_, index) => index + 1),
    unfinishedActionsById: {},
    unfinishedActions: [],
    canStart: false
  },
  action
) => {
  switch (action.type) {
    case SET_ACTION_CREATOR: {
      let actionArr = [...state.actions];
      action.payload.forEach((item, index) => {
        let newAction = {
          id: item.id,
          is_kpi: item.kpi === "1" ? true : false,
          type_action: item.flow_type ? item.flow_type : "",
          event_id: item.event_id ? item.event_id : "",
          event: item.event_name ? item.event_name : "",
          eventColor: item.eventColor ? item.eventColor : "#7CFC00",
          from_id: item.from_id ? item.from_id : "",
          from: item.from ? item.from : "",
          to_list_ids: item.to_list ? item.to_list : "",
          to_list: item.to ? item.to : "",
          cc_list_ids: item.cc_list ? item.cc_list : "",
          cc_list: item.cc ? item.cc : "",
          bcc_list_ids: item.bcc_list ? item.bcc_list : "",
          bcc_list: item.bcc ? item.bcc : "",
          subject: item.subject ? checkIfIsEncodeText(item.subject, "string") : "",
          time: item.phase_number ? Number(item.phase_number) : 1,
          platform_type: item.platform_type ? item.platform_type : "mail",
          message_id: item.message_id ? item.message_id : "",
          body: item.message_body ? checkIfIsEncodeText(item.message_body, "html") : "",
          is_done: item.status === "1" ? true : false,
          file: item.platform_type == "mail" ? createFilesArr(item.file) : checkFile(item.file_link) ? createFileObject(false, item.file_link) : "",
        };
        if (item.flow_type === "cfaQuestionnaire") {
          newAction.questionArr = item.questions
        }
        ///
        if (!item.from_id) {
          newAction["unfinished"] = true;
        }
        else if ((item?.flow_type !== "Decision" && item?.flow_type !== "Analysis") && !item.to_list) {
          newAction["unfinished"] = true;
        }
        ///
        if (item?.active && item?.active === "0") {
          let index = actionArr.findIndex((e) => e.id === item.id);
          if (index >= 0) {
            actionArr.splice(index, 1);
          }
        } else {
          let index = actionArr.findIndex((e) => e.id === item.id);
          if (index >= 0) {
            actionArr.splice(index, 1, newAction);
          } else {
            actionArr.push(newAction);
          }
        }
      });
      if (actionArr?.length > 0) {
        actionArr = actionArr.sort((a, b) => a.time - b.time);
      }
      return { ...state, actions: [...actionArr] };
    }
    case SET_KPI: {
      let kpiArr = [...state.kpis];
      action.payload.forEach((item, index) => {
        let newAction = {
          id: item.id,
          is_kpi: true,
          type_action: item.flow_type ? item.flow_type : "",
          event_id: item.event_id ? item.event_id : "",
          event: item.event_name ? item.event_name : "",
          eventColor: item.eventColor ? item.eventColor : "#7CFC00",
          from_id: item.from_id ? item.from_id : "",
          to_list_ids: item.to_list ? item.to_list : "",
          cc_list_ids: item.cc_list ? item.cc_list : "",
          bcc_list_ids: item.bcc_list ? item.bcc_list : "",
          subject: item.subject ? checkIfIsEncodeText(item.subject, "string") : "",
          time: item.phase_number ? Number(item.phase_number) : 1,
          platform_type: item.platform_type ? item.platform_type : "mail",
          message_id: item.message_id ? item.message_id : "",
          body: item.message_body ? checkIfIsEncodeText(item.message_body, "html") : "",
          is_done: item.status === "1" ? true : false,
          file: item.platform_type == "mail" ? createFilesArr(item.file) : checkFile(item.file_link) ? { uri: item.file_link, file: { name: item.file_name } } : "",
        };
        if (item.flow_type === "cfaQuestionnaire") {
          newAction.questionArr = createQuestionArr(item)
        }
        if (item?.active && item?.active === "0") {
          let index = kpiArr.findIndex((e) => e.id === item.id);
          if (index >= 0) {
            kpiArr.splice(index, 1);
          }
        } else {
          let index = kpiArr.findIndex((e) => e.id === item.id);
          if (index >= 0) {
            kpiArr.splice(index, 1, newAction);
          } else {
            kpiArr.push(newAction);
          }
        }
      });
      return { ...state, kpis: [...kpiArr] };
    }
    case ADD_ACTION_CREATOR: {
      let injectsArr = { ...state.inject };
      let actions = [...state.actions];
      const index = injectsArr[
        action.flag === "sm" ? 3 : action.flag === "news" ? 2 : 1
      ]?.findIndex((inject) => inject.id === action.inject.id);
      if (index > -1) {
        actions.splice(
          actions.findIndex(
            (currentAction) => currentAction.id === action.payload.id
          ),
          1,
          action.payload
        );
        injectsArr[
          action.flag === "sm" ? 3 : action.flag === "news" ? 2 : 1
        ].splice(index, 1);
      } else actions.push(action.payload);

      if (action.typeP === "mail") {
        injectsArr[1].push(action.inject);
      } else if (action.typeP === "news") {
        injectsArr[2].push(action.inject);
      } else {
        injectsArr[3].push(action.inject);
      }

      return {
        ...state,
        actions: [...actions],
        inject: { ...injectsArr },
      };
    }
    case CHANGE_COLUMN_POSITION: {
      let actionsNew = [...state.actions];
      actionsNew = actionsNew.filter((e, i) => i !== action.id);
      let currentAction = state.actions[action.id];
      actionsNew.splice(action.index, 0, currentAction);
      return { ...state, actions: [...actionsNew] };
    }
    case CHANGE_TIME_UNITS: {
      return { ...state, timeUnints: action.payload };
    }
    case SET_FLOW_FILTERS: {
      return { ...state, filters: action.payload };
    }
    case CHANGE_FLOW_ZOOM: {
      return { ...state, zoom: action.payload };
    }
    case MARK_PLAYER: {
      let playersCurrent = action.all;
      if (action.flag) {
        playersCurrent += action.payload;
      } else {
        playersCurrent = playersCurrent.replace(action.payload, "");
      }
      return { ...state, playerCurrent: playersCurrent };
    }
    case MARK_PLAYER_FILTER: {
      return { ...state, playerCurrent: action.payload };
    };
    case MARK_PLAYER_HOVER: {
      return { ...state, playerHover: action.payload };
    }
    case SET_TOOLTIP_ACTION: {
      return { ...state, tooltip: action.payload };
    }
    case SET_NEW_SCROLL: {
      return { ...state, scroll: action.payload };
    }
    case EDIT_ACTION: {
      const temp = [...state.actions];
      const existAction = temp.find((x) => x.id === action.payload.id);
      if (existAction) {
        let inject;
        if (existAction.platform_type === "mail") {
          inject = state.inject[1].find(
            (inject) => inject.id === existAction.message_id
          );
        } else if (existAction.platform_type === "news") {
          inject = state.inject[2].find(
            (inject) => inject.id === existAction.message_id
          );
        } else {
          inject = state.inject[3].find(
            (inject) => inject.id === existAction.message_id
          );
        }
        return {
          ...state,
          editAction: {
            ...existAction,
            inject: inject,
            edit: action.payload.edit,
          },
        };
      }
      return { ...state, editAction: action.payload };
    }
    case DELETE_ACTION_CREATOR: {
      let injects = { ...state.inject };
      let actions = [...state.actions];
      let kpis = [...state.kpis];
      actions = actions.filter(
        (currentAction) => (action.deleteOne ? currentAction.id : currentAction.event_id) !== action.payload
      );
      kpis = kpis.filter(
        (currentAction) => (action.deleteOne ? currentAction.id : currentAction.event_id) !== action.payload
      );
      injects[1] = injects[1].filter(
        (inject) => inject.event_id !== action.payload
      );
      injects[2] = injects[2].filter(
        (inject) => inject.event_id !== action.payload
      );
      injects[3] = injects[3].filter(
        (inject) => inject.event_id !== action.payload
      );

      return { ...state, actions: actions, inject: injects, kpis: kpis };
    }
    case SET_FLOW_TIME_COLUMNS: {
      if (action.payload?.length > 0) {
        let temp = [];
        let actions = action.payload.map((e) => e.time);
        actions = Array.from(new Set(actions));
        let currentT;
        for (let i = 0; i <= action.count; i++) {
          if (actions[i] !== undefined) {
            temp.push(actions[i]);
            currentT = +actions[i];
          } else if (i > actions.length - 1) {
            temp.push(currentT + 1);
            currentT = currentT + 1;
          } else {
            temp.push(temp[i - 1] + 1);
            currentT = +temp[i - 1] + 1;
          }
        }
        return { ...state, flowTimeColumns: temp };
      } else {
        return state;
      }
    }
    case SET_UNFINSHED_FLOW_COLUMNS: {
      if (action.missingByPlayers) {
        state.unfinishedActionsById = action.missingByPlayers;
        const actionIds = {
          missingFrom: [],
          missingTo: []
        };

        Object.values(action.missingByPlayers).forEach(actions => {
          actions.from.forEach(action => actionIds.missingFrom.push(action));
          actions.to.forEach(action => actionIds.missingTo.push(action));
        });
        state.actions.forEach((actionItem) => {
          if (actionIds.missingFrom.findIndex(e => e.id === actionItem.id) >= 0 || actionIds.missingTo.findIndex(e => e.id === actionItem.id) >= 0 || !actionItem?.from_id || !actionItem?.from || !actionItem.to_list_ids) {
            actionItem["unfinished"] = true;
          } else {
            actionItem["unfinished"] = false;
          }
        })
        return { ...state, unfinishedActionsById: state.unfinishedActionsById, unfinishedActions: actionIds, actions: state.actions };
      } else {
        return { ...state, unfinishedActions: action.missingActions }
      }
    }
    case CAN_START: {
      return { ...state, canStart: true }
    }
    default:
      return state;
  }
};
